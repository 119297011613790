import React from 'react'
import moon from 'the-moon'
import Tooltip from '@material-ui/core/Tooltip'
// import { useTranslation } from 'react-i18next'

const wrap = (child, alt) =>
  alt ? <Tooltip title={alt}><span role='img' className='emoji' alt={''}>{child}</span></Tooltip>
    : <span role='img' className='emoji' alt={''}>{child}</span>

const wrapImage = (path) => <img className='imgEmoji' alt='' src={`/assets/${path}`} />
const Icon = ({ type }) => {
  // const { t } = useTranslation()
  return {
    Moon: wrap(moon()),
    Rise: wrap('🡑'),
    Set: wrap('🡓'),
    Lamp: wrap('💡'),
    Age: wrap('📅'),
    Clock: wrap('🕑'),
    Satellite: wrap('🛰'),
    Eye: wrap('👁'),
    SunRise: wrap('🌅'),
    SunMountains: wrap('🌄'),
    Dusk: wrap('🌆'),
    SunSet: wrap('🌇'),
    NightStars: wrap('🌃'),
    NightBridge: wrap('🌉'),
    Sun: wrap('🔆'),
    MilkyWay: wrap('🌌'),
    Planet: wrapImage('planets/generic-icon.png'),
    Mercury: wrapImage('planets/mercury-icon.png'),
    Venus: wrapImage('planets/venus-icon.png'),
    Earth: wrapImage('planets/earth-icon.png'),
    Mars: wrapImage('planets/mars-icon.png'),
    Jupiter: wrapImage('planets/jupiter-icon.png'),
    Saturn: wrapImage('planets/saturn-icon.png'),
    Uranus: wrapImage('planets/uranus-icon.png'),
    Neptune: wrapImage('planets/neptune-icon.png'),
    DateSpan: wrapImage('meteor-showers/date-span-icon.png'),
    Latitude: wrapImage('meteor-showers/latitude-icon.png'),
    Longitude: wrapImage('meteor-showers/longitude-icon.png'),
    Comet: wrapImage('meteor-showers/comet-icon.png'),
    Rocket: wrap('🚀'),
    Map: wrap('🗺'),
    ShootingStar: wrap('🌠'),
    Nebula: wrapImage('deep-sky/nebula-icon.png')
  }[type] || <></>
}
export default Icon
