import React from 'react'

const PollutionMap = (props) => {
  let LocationPicker
  if (typeof window !== 'undefined') {
    LocationPicker = require('react-leaflet-location-picker').default
  }
  return <>
    { !!LocationPicker && <LocationPicker
      showInputs={false}
      showControls={false}
      mapStyle={{ height: '500px', width: 'auto' }}
      onClick={(e) => e.preventDefault()}
      geoserver
      geoURL='https://djlorenz.github.io/astronomy/lp2016/overlay/tiles/tile_{z}_{x}_{y}.png'
      geoLayer='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    />}
  </>
}

export default PollutionMap
