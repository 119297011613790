import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import Astronomy from 'astronomy-engine'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import moon from 'the-moon'
import SunCalc from 'suncalc2'
import getLocation from '../../src/components/get-location'
import Icon from '../../src/components/icons'
import { useTranslation } from 'react-i18next'

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}
const MoonTable = () => {
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`objects.moon.${key}`)
  const now = new Date()

  const { latitude, longitude } = getLocation(i18n.language)

  const times = SunCalc.getMoonTimes(now, latitude, longitude, true)

  const eclipse = Astronomy.SearchLunarEclipse(now)
  const apsis = Astronomy.SearchLunarApsis(now)
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        icons={tableIcons}
        options={{
          search: false,
          sorting: false,
          paging: false,
          draggable: false,
          headerStyle: { fontWeight: 'bold' }
        }}
        columns={[
          { title: 'Title',
            field: 'title'
          },
          { title: 'Value', field: 'value' }
        ]}
        data={[
          {
            title: tt('lunar-phase'),
            value: <><Icon type='Moon' />{tt(moon())}</>
          },
          times.alwaysDown && {
            title: tt('Visibility'),
            value: tt('not-visible')
          },
          times.alwaysUp && {
            title: tt('Visibility'),
            value: tt('always-visible')
          },
          times.rise && {
            title: tt('rise'),
            value: times.rise.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          },
          times.set && {
            title: tt('set'),
            value: times.set.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          },
          {
            title: tt('illuminated-fraction'),
            value: `${(SunCalc.getMoonIllumination(now).fraction * 100).toFixed(2)} %`
          },
          {
            title: tt('age'),
            value: `${Math.round(10 * 29.53 * SunCalc.getMoonIllumination(now).phase) / 10} ${tt('days')}`
          },
          {
            title: tt('apparent-magnitude'),
            value: `${Astronomy.Illumination('Moon', now).mag.toFixed(2)} mag`
          },
          {
            title: tt('next-lunar-eclipse'),
            value: `${(new Date(eclipse.peak)).toLocaleDateString()} (${tt(eclipse.kind)})`
          },
          {
            title: tt('distance'),
            value: `${Math.round(SunCalc.getMoonPosition(now, latitude, longitude).distance).toLocaleString()} km`
          },
          {
            title: tt('next-apsis'),
            value: `${new Date(apsis.time).toLocaleDateString()} (${apsis.kind ? tt('apogee') : tt('perigee')})`
          }
        ].filter(Boolean)}
        title={`${tt('Moon')} ${tt('at')} ${now.toLocaleDateString()}`}
      />
      <br />
    </div>
  )
}
export default MoonTable
