import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import SunCalc from 'suncalc2'
import getLocation from '../../src/components/get-location'
import Icon from '../../src/components/icons'
import { useTranslation } from 'react-i18next'

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}
const SunTable = () => {
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`objects.sun.${key}`)
  const now = new Date()

  const { latitude, longitude } = getLocation(i18n.language)
  const times = SunCalc.getTimes(now, latitude, longitude)
  const locale = { hour: '2-digit', minute: '2-digit' }

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        icons={tableIcons}
        options={{
          search: false,
          sorting: false,
          paging: false,
          draggable: false,
          headerStyle: { fontWeight: 'bold' }
        }}
        columns={[
          { title: tt('Sun-Course'), field: 'title' },
          { title: tt('Time'), field: 'value' }
        ]}
        NightBridge
        data={[
          {
            title: <><Icon type='NightBridge' /> {tt('nightEnd')}</>,
            value: times.nightEnd.toLocaleTimeString([], locale),
            realValue: times.nightEnd
          },
          {
            title: <><Icon type='SunRise' /> {tt('sunrise')}</>,
            value: times.sunrise.toLocaleTimeString([], locale),
            realValue: times.sunrise
          },
          {
            title: <><Icon type='Sun' /> {tt('solarNoon')}</>,
            value: times.solarNoon.toLocaleTimeString([], locale),
            realValue: times.solarNoon
          },
          {
            title: <><Icon type='SunSet' /> {tt('sunset')}</>,
            value: times.sunset.toLocaleTimeString([], locale),
            realValue: times.sunset
          },
          {
            title: <><Icon type='NightStars' /> {tt('night')}</>,
            value: times.night.toLocaleTimeString([], locale),
            realValue: times.night
          },
          {
            title: <><Icon type='MilkyWay' /> {tt('nadir')}</>,
            value: times.nadir.toLocaleTimeString([], locale),
            realValue: times.nadir
          },
          {
            title: <><Icon type='SunMountains' /> {tt('dawn')}</>,
            value: times.dawn.toLocaleTimeString([], locale),
            realValue: times.dawn
          },
          {
            title: <><Icon type='Dusk' /> {tt('dusk')}</>,
            value: times.dusk.toLocaleTimeString([], locale),
            realValue: times.dusk
          }

        ].sort((a, b) => a.realValue > b.realValue)}
        title={`${tt('Sun')} ${tt('at')} ${now.toLocaleDateString()}`}
      />
      <br />
    </div>
  )
}
export default SunTable
