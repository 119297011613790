import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Navigation from '../components/navigation'
import Link from '../components/link'
import 'prismjs/themes/prism-okaidia.css'

const Layout = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { children, pageContext } = props
  const { t } = useTranslation()
  const tt = (key) => t(`layout.${key}`)

  return (
    <div className='site-wrapper'>
      <header className='site-header'>
        <div className='site-title'>
          <Link to={`/`} >{data.site.siteMetadata.title}</Link>
        </div>
        <Navigation pageContext={pageContext || children.props.pageContext} />
      </header>
      {children}
      <footer className='site-footer'>
        <p>&copy; {new Date().getFullYear()} CalSky</p>
        <Link to='/imprint/'>{tt('Imprint')}</Link>
        {/* &bull; Crafted with <span role='img' aria-label='love'>❤️</span> by <a href='https://w3layouts.com'>W3Layouts</a> */}
        <p />
      </footer>
    </div>
  )
}
export default Layout
