import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

export default () => (
  <ThemeToggler >
    {({ theme, toggleTheme }) => (
      <label>
        <input
          type='checkbox'
          className='theme-changer'
          onChange={e => toggleTheme(e.target.checked ? 'light' : 'dark')}
          checked={theme === 'light'}
        />{' '}
        <div className='mode-container'>
          <i className='gg-sun' />
          <i className='gg-moon' />
        </div>
      </label>
    )}
  </ThemeToggler>
)
