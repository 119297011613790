import React from 'react'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'

const LanguagePicker = (props) => {
  const { originalPath = '/' } = props.pageContext
  const { i18n } = useTranslation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages
          }
        }
      }
    `
  )

  return (
    <div className='language-selector-container'>
      { site.siteMetadata.supportedLanguages
        .filter((lang) => lang !== i18n.language)
        .map(supportedLang => (
          <GatsbyLink key={supportedLang}
            aria-label={`Change language to ${supportedLang}`}
            to={`/${supportedLang}${originalPath}`}
          >
            {supportedLang.toUpperCase()}
          </GatsbyLink>
        ))}
    </div>
  )
}

export default LanguagePicker
