import daynight from 'daynight'

const lookup = {
  'de': { latitude: 49, longitude: 13, altitude: 100, place: 'Berlin' },
  'en': { latitude: 51, longitude: -0.1, altitude: 100, place: 'London' }
}

export default (language) => {
  const dn = daynight()

  const defaultLocation = !dn.error
    ? { latitude: dn.coordinates[1], longitude: dn.coordinates[0], place: dn.timezone.split('/')[1] }
    : lookup[language]
  if (typeof window !== 'undefined') {
    if (JSON.parse(window.localStorage.getItem('location'))) {
      return JSON.parse(window.localStorage.getItem('location'))
    } else {
      window.localStorage.setItem('location', JSON.stringify(defaultLocation))
      return defaultLocation
    }
  } else {
    return defaultLocation
  }
}
