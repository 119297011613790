import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { fallbackLanguage } from '../../site-meta-data.json'
const Link = ({ to, ref, ...rest }) => {
  const { i18n } = useTranslation()

  return <GatsbyLink {...rest} to={`/${i18n.language || fallbackLanguage}${to}`} />
}

export default Link
