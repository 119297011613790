import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import Astronomy from 'astronomy-engine'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import getLocation from '../../src/components/get-location'
import Icon from '../../src/components/icons'
const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}
const planets = ['Mercury', 'Venus', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune']
const PlanetsTable = () => {
  const { t, i18n } = useTranslation()

  const location = getLocation(i18n.language)
  const { latitude, longitude, altitude } = location
  const earth = Astronomy.MakeObserver(latitude, longitude, altitude)

  const now = new Date()
  const tt = (key) => t(`objects.planets.${key}`)
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        icons={tableIcons}
        options={{
          search: false,
          sorting: true,
          paging: false,
          draggable: false,
          headerStyle: { fontWeight: 'bold' },
          fixedColumns: {
            left: 1,
            right: 0
          },
          rowStyle: {
            backgroundColor: 'inherit'
          }
        }}
        columns={[
          { title: tt('Planet'), field: 'planet', customSort: (a, b) => a.id > b.id },
          { title: tt('Rise'), field: 'rise', customSort: (a, b) => a.fullRise > b.fullRise },
          { title: tt('Set'), field: 'set', customSort: (a, b) => a.fullSet > b.fullSet },
          { title: tt('Magnitude'), field: 'mag', customSort: (a, b) => a.magValue > b.magValue }
        ]}
        data={
          planets.map((planet, i) => {
            const rise = Astronomy.SearchRiseSet(planet, earth, 1, now, 1)
            const set = Astronomy.SearchRiseSet(planet, earth, -1, now, 1)
            const mag = Astronomy.Illumination(planet, now).mag
            return {
              id: i,
              planet: <><Icon type={planet} /> {tt(planet)}</>,
              rise: rise ? <Tooltip title={rise.toLocaleString('de', { timeZone: 'UTC' })}><p>{(new Date(rise)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p></Tooltip> : ' - ',
              fullRise: rise || ' - ',
              set: set ? <Tooltip title={set.toLocaleString('de', { timeZone: 'UTC' })}><p>{(new Date(set)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p></Tooltip> : ' - ',
              fullSet: set || ' - ',
              mag: mag.toFixed(3).toLocaleString(),
              magValue: mag
            }
          })
        }
        title={`${tt('Planets')} ${tt('at')} ${now.toLocaleDateString()}`}
      />
    </div>

  )
}
export default PlanetsTable
