// gatsby-ssr.js
import React from 'react'
import i18n from './src/i18n/config'
import { I18nextProvider } from 'react-i18next'

import { PageContextProvider } from './src/components/page-context'

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider myKey={{ hi: 2 }} pageContext={props.pageContext}>{element}</PageContextProvider>
}

export const onRenderBody = ({ setPostBodyComponents }, pluginOptions) => {
  return setPostBodyComponents([
    <script
      key={`gatsby-plugin-hotjar`}
      dangerouslySetInnerHTML={{
        __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${'2091669'},hjsv:${'6'}};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `
      }}
    />
  ])
}
