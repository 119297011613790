import React from 'react'
import ThemeChanger from '../components/themeChanger'
import LocationLink from '../components/location-link'
import LanguagePicker from './language-picker'

export default (props) => {
  return (
    <nav className='navigation'>
      <LanguagePicker pageContext={props.pageContext} />
      <LocationLink />
      <ThemeChanger />
    </nav>
  )
}
