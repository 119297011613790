import React from 'react'
import Link from './link'

export default () => (
  <Link to='/location/' className='mode-container'>
    <div className='mode-container'>
      <i className='gg-pin' />
    </div>
  </Link>
)
