import i18next from 'i18next'
import { supportedLanguages, fallbackLanguage } from '../../site-meta-data.json'

i18next.init({
  fallbackLng: fallbackLanguage,
  resources: {
    de: {
      translations: require('../locales/de/translations.json')
    },
    en: {
      translations: require('../locales/en/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    useSuspense: false,
    wait: false
  }
})

i18next.languages = supportedLanguages

export default i18next
