import React, { forwardRef, useState } from 'react'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { useTranslation } from 'react-i18next'
import Icon from '../../src/components/icons'
import showers from './showers.json'

import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const MeteorShowersTable = () => {
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`objects.meteor-showers.${key}`)
  const now = new Date()
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        icons={tableIcons}
        detailPanel={rowData => {
          return (
            <div style={{ fontSize: '17px', textAlign: 'center', marginTop: '5px', marginBottom: '5px' }} >
              <p><Icon type='DateSpan' />{tt('ActivityPeriod')}: {rowData.startDay}.{rowData.startMonth}. {tt('to')} {rowData.endDay}.{rowData.endMonth}.</p>
              <p><Icon type='Longitude' />{tt('RightAscension')}: {rowData.rightAscension}</p>
              <p><Icon type='Latitude' />{tt('Declination')}: {rowData.declination}°</p>
              <p><Icon type='Rocket' />{tt('Speed')}: {rowData.isMaxVariable && '~'}{rowData.speed} {tt('km/s')}</p>
              {rowData.maxZHR && <p><Icon type='Comet' />{tt('maxZHR')}: {rowData.maxZHR}</p>}
            </div>
          )
        }}
        options={{
          search: true,
          sorting: true,
          paging: true,
          draggable: false,
          headerStyle: { fontWeight: 'bold' },
          filtering: true,
          showTextRowsSelected: false,
          pageSize: 5,
          pageSizeOptions: [5, 20, 81]
        }}
        localization={{
          body: { emptyDataSourceMessage: tt('filter-to-strict') },
          toolbar: { searchPlaceholder: tt('Search') },
          pagination: {
            labelDisplayedRows: `{from}-{to} ${tt('of')} {count}`,
            labelRowsSelect: tt('rows')
          }
        }}
        columns={[
          {
            title: 'Name',
            field: i18n.language,
            sorting: false,
            filtering: false
          },
          {
            title: tt('Active'),
            field: 'isVisible',
            sorting: false,
            filtering: true,
            lookup: { true: '✅', false: '❌' },
            defaultFilter: ['true']
          },
          {
            title: 'Symbol',
            field: 'symbol',
            filtering: false,
            sorting: false
          },
          {
            title: tt('Peak'),
            field: 'peak',
            sorting: true,
            filtering: false,
            customSort: (a, b) => a.peakDate > b.peakDate
          },
          {
            title: tt('Rate'),
            field: 'rate',
            filtering: true,
            lookup: { 1: tt('rateMap')['1'], 2: tt('rateMap')['2'], 3: tt('rateMap')['3'], 4: tt('rateMap')['4'] },
            defaultFilter: ['1', '2', '3', '4']
          }
        ]}
        data={
          showers.map(shower => {
            const now = new Date()
            const currentMonth = now.getMonth() + 1
            const currentDay = now.getDate()
            const currentYear = now.getFullYear()

            const isActive = shower.startMonth <= currentMonth && shower.endMonth >= currentMonth &&
             shower.startDay <= currentDay && shower.endDay >= currentDay
            return {
              isVisible: isActive,
              peak: new Date(`${currentYear}-${shower.maxMonth}-${shower.maxDay}`).toLocaleDateString(),
              peakDate: new Date(`${currentYear}-${shower.maxMonth}-${shower.maxDay}`),
              ...shower
            }
          }).sort((a, b) => a.rate > b.rate)
        }
        title={`${tt('meteor-showers')} ${tt('for')} ${now.toLocaleDateString()}`}
      />
      <br />
    </div>

  )
}
export default MeteorShowersTable
