import React, { forwardRef, useState } from 'react'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { useTranslation } from 'react-i18next'
import getLocation from '../../src/components/get-location'
import getISSData from '../../src/components/get-iss-data'

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}

const ISSTable = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`objects.iss.${key}`)
  const location = getLocation(i18n.language)
  const now = new Date()

  getISSData(location)
    .then(newData => {
      if (JSON.stringify(data) !== JSON.stringify(newData)) {
        newData && setData(newData)
        error && setError(false)
      }
    })
    .catch(() => !error && setError(true))

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        icons={tableIcons}
        options={{
          search: false,
          sorting: false,
          paging: false,
          draggable: false,
          headerStyle: { fontWeight: 'bold' }
        }}
        localization={{ body: { emptyDataSourceMessage: error ? tt('error') : tt('calculating') } }}
        columns={[
          { title: tt('time'),
            field: 'flyover'
          },
          { title: tt('duration'),
            field: 'duration'
          }
        ]}
        data={
          data.map((flyover, i) => {
            const flyoverTime = new Date(flyover.risetime * 1000)
            return {
              id: i,
              flyover: flyoverTime.toLocaleDateString(undefined, { day: 'numeric', month: 'short' }) + ' ' + flyoverTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
              duration: `${flyover.duration} ${tt('seconds')}`
            }
          })
        }
        title={`${tt('ISS')} ${tt('at')} ${now.toLocaleDateString()}`}
      />
      <br />
    </div>

  )
}
export default ISSTable
