import React from 'react'
import { useTranslation } from 'react-i18next'
const PageContext = React.createContext({})

export const PageContextProvider = (props) => {
  const { pageContext, children } = props
  const { i18n } = useTranslation()

  if (i18n.language !== props.pageContext.lang) {
    i18n.changeLanguage(props.pageContext.lang)
  }

  return <PageContext.Provider pageContext={pageContext}>{children}</PageContext.Provider>
}

export const usePageContext = () => React.useContext(PageContext)
