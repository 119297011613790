import axios from 'axios'

const getISSData = async ({ latitude, longitude }) => {
  let data = (!isSSR && JSON.parse(window.localStorage.getItem(`issTimes_${latitude}_${longitude}`))) || []
  const storedDataIsOutdated = !data.length || (new Date(data[0].risetime * 1000)) < new Date()

  if (storedDataIsOutdated) {
    return axios.get(
      `/issapi/iss-pass.json?lat=${latitude}&lon=${longitude}&n=8`
    )
      .then(({ data }) => {
        window.localStorage.setItem(`issTimes_${latitude}_${longitude}`, JSON.stringify(data.response))
        return data.response
      })
  } else {
    return data
  }
}
const isSSR = typeof window === 'undefined'

export default getISSData
